import { Grid } from "./grid";
// import { preloadImages } from "./utils";

// Preload  images
// preloadImages(".bentogrid__item-icon, .bentogrid__item-cover").then(() => {
// Initialize grid
// const grid = new Grid(document.querySelector(".bentogrid"));
// });

// Not using imagesloaded because it doesn't deal well with srcset
window.onload = function onWindowLoadImageGrid() {
  // Initialize grid
  new Grid(document.querySelector(".bentogrid"));
};
